@charset "utf-8";
/* CSS Document */
@font-face {
	font-family: 'Glyphicons Halflings';
	src:
		url("https://stackpath.bootstrapcdn.com/bootstrap/3.3.6/fonts/glyphicons-halflings-regular.eot");
	src:
		url("https://stackpath.bootstrapcdn.com/bootstrap/3.3.6/fonts/glyphicons-halflings-regular.eot?#iefix")
		format("embedded-opentype"),
		url("https://stackpath.bootstrapcdn.com/bootstrap/3.3.6/fonts/glyphicons-halflings-regular.woff2")
		format("woff2"),
		url("https://stackpath.bootstrapcdn.com/bootstrap/3.3.6/fonts/glyphicons-halflings-regular.woff")
		format("woff"),
		url("https://stackpath.bootstrapcdn.com/bootstrap/3.3.6/fonts/glyphicons-halflings-regular.ttf")
		format("truetype"),
		url("https://stackpath.bootstrapcdn.com/bootstrap/3.3.6/fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular")
		format("svg")
}

fieldset::-webkit-input-placeholder {
	color: #838384 !important;
	opacity: 1
}

fieldset::-moz-placeholder {
	color: #838384 !important;
	opacity: 1
}

fieldset:-ms-input-placeholder {
	color: #838384 !important;
	opacity: 1
}

fieldset::-ms-input-placeholder {
	color: #838384 !important;
	opacity: 1
}

fieldset::placeholder {
	color: #838384 !important;
	opacity: 1
}

.cl {
	clear: both;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus
textarea:-webkit-autofill, textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
	-webkit-text-fill-color: #838384 !important;;
	background-color: white !important;
	-webkit-box-shadow: 0 0 0px 1000px white inset;
}

.error {
	color: red;
	font-size: 12px;
	margin-top: 3px;
	text-align: left;
	margin-bottom: -2px;
}

a:focus {
	outline: 0px;
}

a {
	color: #27aae1;
}

body {
	font-size: 14px !important;
}

/*********header css**********/
header nav li a {
	padding: 0px 10px !important;
	font-size: 14px;
	border-right: 1px solid #403f3f;
	height: auto;
	text-align: center;
	line-height: 15px;
	color: #403f3f !important;
	text-decoration: none;
}

header nav li:last-child a {
	border-right: 0px;
}

header {
	width: 100%;
}

.logo {
	max-width: 336px;
}

.home-banner .container{position: relative;}

.home-banner .container:after {
    background: #E4F1E6;
    content: '';
    max-width: 405px;
    height: 122%;
    position: absolute;
    top: -72px;
    right:-104px;
    z-index: -1;
    width: 100%;
}


label {
    margin-bottom: 0px !important;
    padding-top: 10px;
	margin-left: 35px;
}


/*********multi-form area********/
/* background:url(/wiseradvisor/v2/images/bnw.jpg) no-repeat center;
background-size: cover; background-attachment: fixed; */
.multi-bg {
	background-color:#27aae1;
	padding: 40px 40px 20px 40px;
	position: relative;
	
}


.multi-bg .form-start {
	width: 76%;
	background: #fff;
	margin: 0 auto;

}

.multi-bg .form-start h1 {
	color: #27aae1;
	font-size: 24px;
	font-family: Arial, sans-serif; /* text-transform: uppercase; */
	padding-top: 10px;
}

.multi-bg .form-start h1 hr {
	margin-top: 0px;
}

.multi-bg .form-start h1 span {
	color: #27aae1;
	font-size: 30px !important;
	font-family: Arial, sans-serif;
}

.multi-bg .form-start span {
    color: #1B75BB;
    font-size: 18px;
    font-weight: 500;
    font-family: Arial, sans-serif;
    line-height: 25px;
}

.multi-bg .form-start h2 {
	color:#1B75BB;
	font-family: Arial, sans-serif;
	font-size:24px;
	margin-top: 25px;
}

.multi-bg .form-start h3 {
    color: #3C3C3B;
    font-size: 18px;
    line-height: 25px;
    margin-top: 30px;
}

.multi-bg .zip-code {
	color:#69AF70;
	font-family: Arial, sans-serif;
	font-size: 17px;
	display: block;
	text-align: left;
}

.multi-bg .review-request {
	width: 90%;
	margin: 0 auto;
}

.multi-bg .additional { 
    width: 100%;
    height: 32px;
    border: 1px solid #1B75BB;
    margin-top: 10px;
    padding-left: 10px;
}
.multi-bg .additional:focus {
    outline: 0;
}
.multi-bg .additional::placeholder{color:#1B75BB;}

.multi-bg .review-request .additional {
	border: 1px solid #69AF70;
	background-color: #ffffff;
	border-radius: 0px;
	color: #838384;
	font-family: Arial, sans-serif;
	font-size: 16px;
	line-height: 12px;
	height: 44px;
	width: 100%;
	padding-left: 8px;
}

.multi-bg .review-request .additional::placeholder {
	color: #b2b2b2;
	line-height: 18px;
}

textarea#additional_comment {
	min-height: 144px;
	line-height: 20px;
}

.btn-update {
	background-color:#1B75BB;
	color: #fff;
	padding: 10px 0;
	border: none;
	width: 100%;
	margin-top: 10px;
	font-weight: 600;
	min-height: 43px;
	margin-bottom: 30px;
	font-size: 18px;
	margin-top: 50px;
}

.multi-bg .multi-step-accordian {
	margin: 20px auto 10px auto;
	width: 80%;
}

.multi-bg .accordian-group .accordian-main {
	position: relative;
}

.multi-bg .accordian-group .accordian-heading h3 {
	margin: 0px auto;
	width: 43%;
	position: relative;
	text-align: left;
}

.multi-bg .accordian-body {
	padding: 10px 0;
	color: #27aae1;
}

.multi-bg .accordian-group .accordian-heading .accordion-toggle {
	color: #3c3c3b;
	font-family: Arial, sans-serif;
	font-size: 17px;
	line-height: 17px;
	text-decoration: none;
	display: block;
	margin-bottom: 10px;
}

.multi-bg .accordian-group .accordian-heading .accordion-toggle:after {
	font-family: 'Glyphicons Halflings';
	content: "\e252";
	position: absolute;
	color: #3c3c3b;
	right: 0;
	font-size: 13px;
	font-weight: bold;
}

/*.multi-bg .accordian-group .accordian-heading .accordion-toggle.collapsed:after
  {
  content: "\e250";
}*/
.multi-bg .accordian-body p {
	color: #808080;
	font-family: Arial, sans-serif;
	font-size: 13px;
	line-height: 18px;
	margin-bottom: 5px;
}

.multi-bg .accordian-body h4 {
	font-family: Arial, sans-serif;
	font-size: 16px;
	line-height: 22px;
}

.multi-bg .collapse.in {
	display: block;
}

.multi-bg .honourable-mention {
	background: rgba(255, 255, 255, 1);
	width: 74%;
	margin: 20px auto 0 auto;
	padding: 1%;
}

.multi-bg .honourable-mention h2 {
	font-weight: bold;
	font-size: 12px;
	line-height: 40px;
	display: block;
	color: #000;
	float: left;
}

.honourable-mention img {
	float: left;
	width: 86%;
}

.multi-bg .honourable-mention ul {
	padding-left: 15px;
	margin: 0;
}

.multi-bg .honourable-mention li {
	margin-right: 19px;
	float: left;
	list-style: none;
	margin-right: 15px;
}

.multi-step-accordian.phone, .phone-back {
	display: none;
}

.multi-step-accordian.desktop, .desktop-back {
	display: block;
}

fieldset {
	border: none;
	padding: 0;
}

.line {
	width: 4px;
	height: 76px;
	background:#69AF70;
	position: absolute;
	transform: translate(-50%, -50%) rotate(45deg);
	right: 45%;
	top: 50%;
	z-index: 9;
	border: 1px solid #fff;
	border-top: 0;
	border-bottom: 0;
}

figure.icon-block:hover .line {
	background: #fff;
}

.multi-bg .top-space {
	width: 80%;
	margin: 0px auto;
	margin-top: 26px;
}

.col.text-center.bottom-space {
	margin-bottom: 20px;
}

.top-space label {
	position: absolute;
	left: 46px;
	top: 40px;
}

.check-option {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.check-option input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
	border: 1px solid #27aae1;
	border-radius: 3px
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.check-option input:checked ~ .checkmark:after {
	display: block;
}

.check-option .checkmark:after {
	left: 4px;
	top: 1px;
	width: 5px;
	height: 8px;
	border: solid #1B75BB;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.multi-bg .icon-block {
	border: 3px solid #1B75BB;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	position: relative;
	margin: 0 auto;
}

.multi-bg .icon-block svg {
	width: 50px;
	height: 50px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	fill:#1B75BB;
}

.multi-bg .icon-block:hover {
	background:#1B75BB;
	cursor: pointer;
}

.multi-bg .icon-block:hover svg g {
	fill: #fff;
}

.multi-bg .icon-block:hover svg {
	fill: #fff;
}

.multi-bg h4 {
	font-family: Arial, sans-serif;
	font-size: 13px;
	color: #1B75BB;
	margin-top: 15px;
	font-weight: 700;
}

.btn-update1 {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	background-color: #27aae1;
	color: #fff;
	padding: 10px 0;
	border: none;
	width: 40%;
	margin-top: 5px;
	font-weight: 600;
	float: left;
	margin-left: 20px;
}

.btn-pre {
	width: 19px;
	height: 35px;
	padding: 10px 0;
	border: none;
	margin-left: 7px;
	position: absolute;
	left: 20px;
	top: 40%;
	font-size: 30px !important;
	color:#1B75BB;
}

/*.btn-pre:hover{box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); color:#fff;}*/
#helpdeskform {
	position: relative;
	min-height: 200px;
}

#helpdeskform .field2, .field3, .field4, .field5, .field6, .field7,
	.field8, .field9, .field10, .field11, .field12, .field13, .field14 {
	display: none;
}

fieldset.field13 h2, fieldset.field14 h2, fieldset.field15 h2 {
	font-size: 22px !important;
}

fieldset.field13, .field14, .field15, .field11, .field1 {
	padding: 0 22%;
}

#helpdeskform .action-button:focus {
	outline: none;
}

#helpdeskform .action-button {
	width: 150px;
	cursor: pointer;
}



.shield {
	text-align: left;
	padding-top: 0px;
	padding-bottom: 0px;
	margin-bottom: 0px;
	position: relative;
}

.shield span {
	color: #636262 !important;
	font-size: 11px !important;
}

p.shield svg {
	width: 4%;
	float: left;
}

.disclosures-checkbox {
	color: #838384 !important;
	font-size: 13px !important;
	margin-left: 0px;
}

.disclosures-checkbox input[type=checkbox] {
	float: left;
	width: 20px;
	margin-top: 3px;
	padding-left: 0px;
}

.disclosures-checkbox label {
	float: left;
	width: 95%;
	padding-top: 0px;
	line-height: 19px;
}

.icon-active {
	background:#1B75BB;
	cursor: pointer;
}

.icon-active svg {
	fill: #fff !important;
}

/* .disclosures-checkbox{color: #838384!important;font-size: 13px!important;margin-left: 0px;}
.disclosures-checkbox input[type=checkbox]{float:left;width:20px;margin-top: 3px;padding-left: 0px;}
.disclosures-checkbox label{float:left;width: 95%;padding-top:0px;line-height: 19px;} */
#result2 h2 {
	color: #69AF70;
	font-size: 22px;
	margin: 20px 0px 0px 0;
}

.circle {
	border-radius: 50%;
	display: inline-block;
	position: relative;
}

.circle .icon-block {
	background: #27aae1;
}

.circle:after {
	content: "";
	display: block;
	background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 110%);
	border-radius: 50%;
	position: absolute;
	left: 11%;
	right: 0;
	bottom: 0;
	height: 116px;
	width: 116px;
	z-index: 99999999;
	top: -17px;
	display: none;
}
/*
.full-screen-img {
	background: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	position: fixed;;
	z-index: 999;
}

.circle {
	display: inline-block;
	position: relative;
}

.circle .highlight {
	position: absolute;
	left: 215px;
	width: 200px;
	z-index: 999;
}

.circle .highlight:before {
	background: url(images/left-point.png) no-repeat;
	content: '';
	position: absolute;
	width: 92px;
	height: 72px;
	left: -90px;
}

.circle-1:after {
	content: "";
	display: block;
	background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 110%);
	border-radius: 50%;
	position: absolute;
	right: 70px;
	bottom: 0;
	height: 116px;
	z-index: 99999999;
	top: -33px;
	width: 116px;
}

.circle-1 .btn-next {
	position: relative;
	z-index: 999999999;
}

.circle-1 .highlight {
	position: absolute;
	left: 215px;
	width: 200px;
	z-index: 999;
}

.circle-1 .highlight:before {
	background: url(/wiseradvisor/v2/images/left-point.png) no-repeat;
	content: '';
	position: absolute;
	width: 92px;
	height: 72px;
	left: -90px;
}*/

/*.choose-option p {
	color: #fff;
}
*/
/*.skin-btn {
	border: 0;
	font-size: 14px;
	color: #fff;
	background: transparent;
	cursor: pointer;
}*/

/*.skin-btn:hover {
	
}*/

/*.got-it-btn {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 18px;
	border: 1px solid #707070;
	color: #27aae1;
	font-size: 14px;
	cursor: pointer;
	padding: 1% 10%;
	background: #fff;
	margin-left: 10px;
	font-weight: 600;
}*/

.circle-1 {
	display: inline-block;
	position: relative;
}

.media-left {
	margin-right: 10px;
}

.step-form, .desktop {
	display: block;
}

.step-form-1, .mobile {
	display: none;
}

/*.circle .icon-block {
	z-index: 999999999999999;
}

.circle .icon-block svg {
	fill: #fff !important
}*/

img#loading-1 {
	margin: 10px 0;
}
/*********footer css**********/
.upper_footer {
	text-align: center;
	/*background:#F0F6F2;*/
	background: rgb(241,241,241);
	margin: 0;
}

.upper_footer ul {
	display: inline-block;
}

.upper_footer ul li {
	list-style: none;
	float: left;
	padding: 0px 20px;
	line-height: 0;
	font-size: 16px;
}

.upper_footer ul li a {
	color: #757575;
	line-height: 1;
	background: none;
}



@media screen and (max-width: 410px) and (min-width: 320px) {
	label.check-option.abc {
		left: 76px;
	}
}

@media screen and (max-width: 767px) and (min-width: 320px) {
	.communicatingyes{
	display: block !important;
	
	}
	.navbar-brand {
    width: 45% !important;
	}
	#helpdeskform .action-button {
    max-width: 122px;}
    section.feedback-service {
    padding: 0 0% !important;
	}
	.ranking-scale label {
    width: 25.3% !important;
	}
	.top-space label {
		left: 6px;
		top: 16px;
	}
	.featured_section h3 {
		line-height: 5px !important;
	}
	.multi-bg {
		padding: 10px 0px;
		background: transparent;
	}
	.multi-bg .multi-step-accordian {
		margin: 0 auto;
		width: 96%;
		padding: 10px 2% 3px 2%;
	}
	.multi-bg .review-request {
		width: 80%;
	}
	.multi-bg .accordian-group .accordian-heading h3 {
		width: 68%;
	}
	.multi-bg .accordian-body h4 {
		margin-bottom: 0;
	}
	.multi-bg .form-start {
		width: 95% !important;
		background: #fff;
	}
	.multi-bg .honourable-mention {
		background: transparent;
		width: 96%;
	}
	.multi-bg .honourable-mention h2 {
		padding-bottom: 0px;
		margin-bottom: 0;
		line-height: 20px;
	}
	.honourable-mention img {
		float: left;
		width: 98%;
	}
	.upper_footer ul {
		padding: 0px;
	}
	.upper_footer ul li {
		padding: 0 5px;
		margin-bottom: 8px;
		font-size: 15px;
	}
	.circle .highlight {
		position: absolute;
		left: -32px;
		width: 200px;
		z-index: 999;
		top: 120px;
	}
	.circle .highlight:before {
		left: initial;
		transform: rotate(90deg);
		right: -40px;
		top: -28px;
	}
	.btn-pre-1 {
		left: -11px !important;
		top: 41% !important;
	}
	.multi-bg .step-form-1 h1 {
		color: #27aae1;
		font-size: 22px;
		margin-top: 5px;
		font-family: Arial, sans-serif;
		text-transform: uppercase;
	}
	.multi-bg .step-form-1 span {
		color: #27aae1;
		font-size: 15px !important;
		font-family: Arial, sans-serif;
		line-height: 10px;
	}
	.multi-bg .step-form-1 {
		margin: 0 auto 10px auto;
		width: 96%;
		text-align: center;
	}
	.step-form-1, .phone {
		display: block !important;
	}
	.multi-bg .form-start h2 {
		margin-top: 10px !important;
		font-size: 22px;
	}
	.phone-back {
		width: 85px;
		height: 35px;
		border: none;
		margin: 30px auto 0 auto;
		background: transparent;
		  color: #002D55;
		text-align: center;
		cursor: pointer !important;
		display: block;
	}
	.btn-update {
		margin-top: 10px;
		padding: 10px 0px;
	}
	.desktop-back {
		display: none !important;
	}
	.upper_footer p {
		font-size: 13px;
	}
	.padd-left {
		padding-left: 10%;
	}
	.padd-right {
		padding-right: 10%;
	}
	.current p {
		font-size: 14px;
	}
	.bottom-space {
		margin-bottom: 10px;
		padding: 0 24px;
	}
	.thanku-mess {
		line-height: 26px !important;
	}
	p.shield svg {
		display: none;
	}
	.multi-bg .accordian-group .accordian-heading .accordion-toggle:after {
		right: 8%;
	}
	/*.pin-code {
		position: inherit !important;
	}*/
	fieldset.field13, .field14, .field15, .field11, .field1 {
		padding: 0 2%;
	}
}

@media screen and (max-width: 480px) and (min-width: 320px) {
	/* .disclosures-checkbox label{width: 90%!important;text-align: left!important;
    font-size: 13px!important;} */
	.zipcodebox {
		width: 40% !important
	}
	.read {
		font-size: 13px !important;
	}
	.step-form-1, .mobile {
		display: block;
	}
	.multi-bg .form-start h1 span {
		font-size: 24px !important;
	}
	.multi-bg .accordian-group .accordian-heading .accordion-toggle:after {
		right: -8% !important;
	}
	.multi-bg .container {
		padding: 0px !important;
	}
	.multi-bg .container .row {
		margin: 0px !important;
	}
	.multi-bg .container .row .col-12 {
		padding: 0px !important;
	}
	.multi-bg .review-request {
		width: 96% !important;
	}
	.multi-bg .form-start h1 {
		font-size: 20px !important;
	}
	.multi-bg .form-start span {
		font-size: 16px !important;
		line-height: 20px !important;
	}
	.multi-bg .form-start h2 {
		margin: 10px 0 !important;
	}
	/*.multi-bg .review-request .additional {
		margin-bottom: 10px !important;
	}*/
	
	#progressbar {
		width: 80% !important;
		margin: 20px auto !important;
	}
	.multi-bg .accordian-group .accordian-heading .accordion-toggle {
		font-size: 13px !important;
	}
	.multi-bg .top-space {
		width: 100% !important;
		margin: 20px auto !important;
	}
	.multi-bg .shield {
		padding-bottom: 10px;
		padding-top: 0px !important;
	}
	.multi-bg .shield span {
		font-size: 10px !important;
		width: 98% !important;
		float: left !important;
		margin-left: 0px !important;
	}
	.form-start .error {
		margin-bottom: 0px !important;
	}
	p.shield svg {
		display: none;
	}
}

@media screen and (max-width: 991px) and (min-width: 320px) {
	.navbar-toggler {
		display: none;
	}
	.mobile-last {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
	}
}

.navbar-brand {
	width: 225px;
	display: block;
}
/*
.we-do-content {
	position: relative;
	top: -50px;
	background: #fff;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.75);
	padding: 10px;
	height: 220px;
}

.bg-stirp {
	background: rgba(20, 153, 214, 1);
	background: -moz-linear-gradient(left, rgba(20, 153, 214, 1) 0%,
		rgba(1, 82, 152, 1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(20, 153, 214,
		1)), color-stop(100%, rgba(1, 82, 152, 1)));
	background: -webkit-linear-gradient(left, rgba(20, 153, 214, 1) 0%,
		rgba(1, 82, 152, 1) 100%);
	background: -o-linear-gradient(left, rgba(20, 153, 214, 1) 0%,
		rgba(1, 82, 152, 1) 100%);
	background: -ms-linear-gradient(left, rgba(20, 153, 214, 1) 0%,
		rgba(1, 82, 152, 1) 100%);
	background: linear-gradient(to right, rgba(20, 153, 214, 1) 0%,
		rgba(1, 82, 152, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1499d6',
		endColorstr='#015298', GradientType=1);
	border-radius: 10px;
	min-height: 100px;
}

.bg-stirp-outer {
	width: 80%;
	margin: 0 auto;
}*/

h2.distance {
	margin: 5px 100px 5px 100px !important;
}

.pin-code input::placeholder, .bottom-pin input::placeholder {
	color: #999;
	opacity: 1
}

.pin-cod input::-moz-placeholder, .bottom-pin input::placeholder {
	color: #999;
	opacity: 1
}

.pin-code input:-ms-input-placeholder, .bottom-pin input::placeholder {
	color: #999
}

.pin-code input::-webkit-input-placeholder, .bottom-pin input::placeholder
	{
	color: #999
}

a:focus {
	outline: 0;
	text-decoration: none;
}

/*.bg1 .need .input:focus {
	outline: none;
}
*/

.navbar-brand {
	padding: .3125rem 0px;
}

.multi-bg .accordian-body #msg1, .multi-bg .accordian-body #msg2,
	.multi-bg .accordian-body #msg3, .multi-bg .accordian-body #msg4,
	.multi-bg .accordian-body #msg5, .multi-bg .accordian-body #msg6,
	.multi-bg .accordian-body #msg7 {
	padding-bottom: 10px;
	border-bottom: 1px dashed #747474;
}

.disclosures-checkbox {
	color: #636262 !important;
	font-size: 13px !important;
	margin-left: 0px;
}

.disclosures-checkbox input[type=checkbox] {
	float: left;
	width: 20px;
	margin-top: 3px;
	padding-left: 0px;
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
}

.disclosures-checkbox label {
	float: left;
	width: 100%;
	padding-top: 0px;
	line-height: 19px;
	position: relative;
	cursor: pointer;
	text-align: left;
}

.disclosures-checkbox input:checked+label:after {
	content: '';
	display: block;
	position: absolute;
	top: 5px;
	left: 4px;
	width: 5px;
	height: 10px;
	border: solid #636262;
	border-width: 0 3px 3px 0;
	transform: rotate(45deg);
}

.disclosures-checkbox label:before {
	content: '';
	-webkit-appearance: none;
	background-color: #FFFFFF;
	border: 1px solid #636262;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px
		rgba(0, 0, 0, 0.05);
	padding: 6px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
}

.disclosures-checkbox label a {
	color: #636262;
}

.disclosures-checkbox label a:hover {
	color: #27aae1 !important;
}

@media screen and (min-width:320px) and (max-width:640px) {
	.disclosures-checkbox label {
		width: 90% !important
	}
}

.radio {
	display: block;
	width: 75%;
	text-align: left;
	margin-bottom: 0;
	padding-bottom: 0px;
	border-bottom: none;
	margin-left: 15px;
}

.radio label {
	font-size: 19px;
	color: #27aae1;
	padding-top: 5px;
}

.radio input[type=radio] {
	margin-right: 10px;
}

.first-page {
	padding: 2%;
	width: 50%;
	margin-left: -30px;
	margin-top: 20px;
}


/*.pin-code {
	position: absolute;
	width: 100%;
	bottom: 90px;
}*/

section.home-banner {
	position: relative;
	    margin-bottom: 50px;
}

#result2 h3 {
	font-size: 19px;
	line-height: 30px;
	color: #183A68;
}

.thanku-mess {
	font-size: 32px !important;
	border: 2px solid #69AF70;
	background-color: #69AF70;
	color: #fff !important;
	margin-bottom: 2px;
	line-height: 45px;
	letter-spacing: 1px;
	border-left: 0;
	border-right: 0px;
	padding: 10px;
	position: relative;
	word-break: break-word;
	border-radius: 15px 15px 0px 0px;

}

.thanku-mess hr {
	background: #fff;
	margin: 0px;
	height: 2px;
	margin-top: 20px !important;
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
}

.step-heading {
	font-size: 25px;
	border: 2px solid #1B75BB;
	text-transform: uppercase;
	background-color: #1B75BB;
	color: #fff !important;
	margin-bottom: 2px;
	line-height: 35px;
	font-weight: bold;
	letter-spacing: 1px;
	border-left: 0;
	border-right: 0px;
}

.step-heading hr {
	margin: 0px;
	background: #fff;
	height: 2px;
}

#sortable img {
	width:35px;
}

.thank-you-para {
	color: #27AAE1;
	line-height: 19px;
	margin-top: 18px;
}

#result2 a {
	color: #676767;
}

@media screen and (max-width:991px) and (min-width:768px) {
	.home-banner .col-md-5 {
		flex: 100%;
		max-width: 100%;
	}
	.home-banner .col-md-7 {
		flex: 100%;
		max-width: 100%;
	}
	fieldset.field10 p {
		width: 70%;
		margin: 0 auto;
	}
	fieldset.field13, .field14, .field15, .field11, .field1 {
		padding: 0px 15% !important;
	}
	/*section.home-banner .row {
		display: inherit !important;
	}*/
	/*.pin-code {
		position: inherit !important;
	}*/
	.heading h2 {
		font-size: 20px;
	}
	.home-banner .heading h1 {
		font-size: 26px;
		line-height: 34px;
		margin-top: 30px;
	}
	.home-banner .heading p {
		font-size: 15px;
		margin-top: 38px;
	}
	#sortable p {
		padding-right: 0px !important;
	}
	.multi-bg .accordian-group .accordian-heading h3 {
		width: 70% !important;
	}
	.we-do-content {
		margin-bottom: 15px;
		min-height: 305px !important;
	}
	.multi-bg {
		margin-top: 0px;
	}
	.shield span {
		font-size: 10px !important;
	}
	.honourable-mention img {
		width: 100%;
	}
	.multi-bg .form-start h2 {
		padding: 0 10px;
	}
	
}

.feedback-service label{ margin: 0 15px 0 0; padding: 0px; color:#1B75BB;  padding-bottom: 10px; font-weight: 700;}
.feedback-service label input[type="radio"], .feedback-service label input[type=checkbox] { margin-right: 6px;}
 .scale-rating label:nth-child(5) {
    border-right: 1px solid #3B77BB;
}
    .scale-rating label:hover {
    background: #27AAE1;
}
    .scale-rating label {
    position: relative;
    -webkit-appearance: none;
    outline: 0!important;
    height: 33px;
    margin: 0 0px 0 0;
    width: 20%;
   /* width: calc(20% - 7px);*/
    float: left;
    cursor: pointer;
    border: 1px solid #3B77BB;
}
   .scale-rating {
    margin: 5px 0 15px;
    display: inline-block;
    width: 100%;
}
    .scale-rating label:before {
    content: attr(value);
    top: 7px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    vertical-align: middle;
    z-index: 2;
}
    .scale-rating input[type=radio] {
    position: absolute;
    -webkit-appearance: none;
    opacity: 0;
    outline: 0!important;
    height: 33px;
    margin: 0 5px 0 0;
    width: 100%;
    float: left;
    cursor: pointer;
    z-index: 3;
}
    .scale-rating label:before {
    content: attr(value);
    top: 7px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    vertical-align: middle;
        z-index: 2;}
    .scale-rating label label {
    border: 0;
}

.radioactive {
    background-color: #1b75bb;
    color:  #fff !important;
}
.ranking-scale label {
    width: 30.3%;
    text-align: center;
}
section.feedback-service {
    padding: 0 6%;
}
.thanks-heading {
	text-transform: none !important;
}
.communicatingyes{
	display: inline-block;
	margin-right: 20px;
	color: #1b75bb;
	font-weight: 700;
}
.multi-bg .form-start span a {
    color: #1b75bb;
}

#progressbar {
	overflow: hidden;
	counter-reset: step;
	padding: 0;
	box-sizing: border-box;
	color: #f6f6f6 !important;
	position: relative;
	border-radius: 3px;
	height: auto;
	width: 40% !important;
	margin: 20px auto !important;
}

#progressbar li:last-child {
	margin-right: 0px;
}

#progressbar li {
	list-style-type: none;
	width: 6.9%;
	height: 100%;
	float: left;
	position: relative;
	color: #F6F6F6;
	position: relative;
	margin: 0 0.4%;
}

#progressbar li:before {
	content: counter(step);
	width: auto;
	color: transparent;
	display: block;
	background: #ECECEC;
	height: 25px;
}

#progressbar li.active:before, #progressbar li.active:after {
	background:#1B75BB;
	margin-left: 0px;
	-webkit-transition: width 2s, height 4s;
	/* For Safari 3.1 to 6.0 */
	transition: width 2s, height 4s;
	width: 100%;
}
.textarea{
	min-height: 100px;
}